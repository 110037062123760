<template lang="html">
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(handleSubmission)" class="Form">
      <div class="Form__wrapper">
        <formly-form
          :form="form"
          :model="model"
          :fields="fields"
          tag="div"
          class="Form__group"
        ></formly-form>
      </div>
      <button class="btn btn--primary btn--min">Sign Up</button>
    </form>
  </ValidationObserver>
</template>

<script>
  import Time from '@/helpers/TimeHelper'
  export default {
    name: 'RegisterPlayer',
    props: {
      register: {
        type: Boolean,
        default: false,
      },
      division: {
        type: Object,
        required: false,
      },
    },
    data: function() {
      return {
        positions: this.$store.state.games.positions,
        model: {
          photo:
            'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?f=y&d=mp',
          name: null,
          username: null,
          email: null,
          birthday: null,
          password: null,
          password_confirmation: null,
          position: null,
          secondary_position: null,
          competition_level: null,
          special_considerations: '',
          emergency_contact_name: null,
          emergency_contact_number: null,
        },
        form: {},
        fields: [
          {
            key: 'photo',
            type: 'profilePicture',
            rules: 'image|size:3072',
            templateOptions: {
              type: 'file',
              label: 'Upload profile picture',
            },
          },
          {
            key: 'name',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'text',
              label: 'Full Name',
              layout: 'full',
            },
          },
          {
            key: 'username',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'text',
              label: 'Username',
              layout: 'full',
            },
          },
          {
            key: 'email',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'email',
              label: 'Email',
              layout: 'half',
            },
          },
          {
            key: 'birthday',
            type: 'datepicker',
            rules: {
              required: true,
              birthday_validation: {
                from_birthday: this.division
                  ? this.division.from_birthday
                  : null,
                to_birthday: this.division ? this.division.to_birthday : null,
                year_eligible: this.division
                  ? this.division.year_eligible
                  : null,
              },
            },
            templateOptions: {
              type: 'date',
              format: 'DD/MM/YYYY',
              defaultPanel: 'year',
              defaultValue: Time.dateYearsFromNow(-4),
              disableDatesMode: 'birthday',
              label: 'Date of birth',
              layout: 'half',
            },
          },
          {
            key: 'password',
            type: 'input',
            rules: 'required|password:@password_confirmation',
            templateOptions: {
              type: 'password',
              label: 'Password',
              layout: 'half',
            },
          },
          {
            key: 'password_confirmation',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'password',
              label: 'Repeat Password',
              layout: 'half',
            },
          },
          {
            key: 'position',
            type: 'dropdownPrefferedPositions',
            rules: 'required',
            templateOptions: {
              label: 'Preferred position',
              layout: 'half',
              connected_keys: ['position', 'secondary_position'],
            },
          },
          {
            key: 'secondary_position',
            type: 'dropdownPrefferedPositions',
            rules: 'required',
            templateOptions: {
              label: 'Secondary position',
              layout: 'half',
              connected_keys: ['position', 'secondary_position'],
            },
          },
          {
            key: 'competition_level',
            type: 'textarea',
            rules: 'required',
            templateOptions: {
              label: 'Highest level of play & year played',
              layout: 'full',
            },
          },
          {
            key: 'special_considerations',
            type: 'textarea',
            rules: '',
            templateOptions: {
              label: 'Special considerations',
              layout: 'full',
            },
          },
          {
            key: 'emergency_contact_name',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'text',
              label: 'Emergency Contact Name',
              layout: 'half',
            },
          },
          {
            key: 'emergency_contact_number',
            type: 'input',
            rules: 'phoneNumber|required',
            templateOptions: {
              label: 'Emergency Contact Number',
              layout: 'half',
            },
          },
        ],
      }
    },
    mounted() {
      this.$emit('init')
    },
    methods: {
      handleSubmission() {
        if (this.register) {
          this.$store.dispatch('AUTH_REQUEST', {
            ...this.model,
            action: 'register',
            account_type: 'player',
          })
        } else {
          this.$store.dispatch('submissions/NEW_PLAYER_SUBMISSION', {
            data: this.model,
            slug: this.$route.params.slug,
          })
        }
      },
    },
  }
</script>

<style lang="css" scoped></style>
