<template lang="html">
  <div class="register">
    <h1 class="title-3">Create your player profile</h1>
    <p class="text-xlg text-semi">Complete the signup form</p>
    <RegisterPlayer register></RegisterPlayer>
    <router-link :to="{name: 'login'}" class="clickable nav-link block mt-16 text-md">Log in</router-link>
  </div>
</template>

<script>
import RegisterPlayer from '@/components/Forms/RegisterPlayer'

export default {
  name: "EventRegistration",
  components: {RegisterPlayer},
  data: function () {
    return {
      item: null,
      wasUnregistered: false,
      wasUnapplied: false,
    }
  },
  beforeMount() {
    if (this.isAuthenticated) {
      this.redirectToEvents()
    }
  },
  methods: {
    redirectToEvents() {
      this.$router.push('/events')
    },
  },
  watch: {
    isAuthenticated(val) {
      if (val) {
        this.redirectToEvents()
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
